/*

export interface Region {
  type: string
  endpoint: string
  httpMethod?: string
}

export interface UsageRecord {
  threadName: string
  region: Region
  time: number
  count: number
  callsStart: number[]
  callsEnd: number[]
  logItems: any[][]
}


export interface EndpointSummary {
  endpoint: string
  totalTime: number
  totalCount: number
  callsStart: number[]
  callsEnd: number[]
  logItems: any[][]
}

 */

export interface LogItem {
  principal?: string
  accessToken?: string
  runnable?: number
  callable?: number
  objectId?: number
}

export interface ChartData {
  x: number
  yCPU: number
  yTraffic: number
  yStorage: number
  yActivity: number
}

export interface ChartDataDto {
  timeStamps: number[]
  cpuPoints: number[]
  trafficPoints: number[]
  storagePoints: number[]
  activityPoints: number[]
}

export interface SummaryDto {
  cpuTime: number
  traffic: number
  storage: number
  count: number
}

export interface MethodDto {
  method: string
  summary: SummaryDto
}

export interface ListSummariesDto {
  directSummary: SummaryDto
  indirectSummary: SummaryDto
  directMethods: MethodDto[]
  indirectMethods: MethodDto[]
}

export interface EndpointSummaryDto extends ListSummariesDto {
  type: string
  endpoint: string
}

export interface SessionEndpointSummaryDto extends ListSummariesDto {
  type: string
  endpoint: string
  userSession: string
  start: number
  end: number
}

export interface StatisticDataDto {
  entryPointsSummary: EndpointSummaryDto[]
  sessionSummaries: SessionEndpointSummaryDto[]
  graphData: ChartDataDto
}

export interface EndpointSessionData extends SummaryDto {
  title: string
}

export interface SessionEndpointData extends SummaryDto {
  title: string
  start: number
  end: number
  indirectMethods: MethodData[]
  directMethods: MethodData[]
}

export const enum MethodType {
  DIRECT,
  INDIRECT,
}

export interface MethodData extends SummaryDto {
  title: string
  type?: MethodType
}

export interface EndpointData extends SummaryDto {
  title: string
  indirectMethods: MethodData[]
  directMethods: MethodData[]
  sessions: EndpointSessionData[]
}

export interface SessionData extends SummaryDto {
  title: string
  start: number
  end: number
  endpoints: SessionEndpointData[]
}

export interface DashboardData {
  endpointsData: EndpointData[]
  sessionsData: SessionData[]
  chartsData: ChartData[]
}
