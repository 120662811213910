import { Axios, AxiosError, AxiosProgressEvent, AxiosRequestConfig } from 'axios'
import { Flag } from 'components/ps-chart/models/Flag'
import { isAxiosRequestCancelled } from 'utils/axios'
import {
  getAllProjectsUrl,
  getAndroidPluginVersionsUrl,
  getAnnotationsUrl,
  getBuildPropertiesUrl,
  getExtensionRequestUrl,
  getFlagsUrl,
  getFlowTracesUrl,
  getFlowUrl,
  getGlobalLiveDemoUrl,
  getInstructionsStateUrl,
  getMetricsUrl,
  getNamedLinksUrl,
  getOnboardingRequestsUrl,
  getProjectsSummaryUrl,
  getProjectUrl,
  getShareUrlUrl,
  getTeamsUrl,
  getTraceConnectionsUrl,
  getTraceEntityUrl,
  getTraceFileUrl,
  getTraceSettingsUrl,
  getTraceVideoMetadataUrl,
  getUserUrl,
  VERSION_PREFIX,
} from 'api/urls'
import { TraceDataDto } from 'components/ps-chart/models/TraceDataDto'
import {
  AndroidPluginVersionsDto,
  AnnotationDto,
  AnnotationSliceSuggestionDto,
  ApkSupportIssueDtoOut,
  ApkSupportIssueReq,
  AuthenticatedUserDto,
  BuildAndUploadRequestSpecDto,
  BuildDto,
  BuildUploadContextDto,
  ChartPageParams,
  CheckInviteTokenRequest,
  CheckInviteTokenResponse,
  CheckRecoveryTokenRequest,
  ChoreographerReq,
  ComputationView,
  CreateAnnotationDto,
  CreateFlowDto,
  CreateFreeTrialOnboardingRequestDto,
  CreateNamedLinkDto,
  CreateProjectDto,
  CreateTeamDto,
  CreateTraceDto,
  CreateTraceLocalFlagDto,
  DeleteTraceLocalFlagDto,
  DevicesDto,
  FlowComputationReq,
  FlowDto,
  FlowReq,
  Flows,
  FlowTraceReq,
  FreeTrialExtensionDto,
  FreeTrialOnboardingRequestDto,
  FreeTrialRequest,
  GetConnectionsDto,
  GlobalLiveDemoDto,
  ImageDataDto,
  InstructionsStateDto,
  InviteUserToProjectDto,
  InviteUserToTeamDto,
  InviteWhitelistReq,
  MetricsDto,
  JumpToSourceRequestDto,
  NamedLinkDto,
  OktaSignInResponse,
  PostTraceEntity,
  ProjectAccessRequest,
  ProjectDto,
  ProjectReq,
  ProjectRoleApiValue,
  ProjectUserDto,
  ProjectUsersResponse,
  PsLiteRequest,
  RaUserNotificationConfigDto,
  RecoveryTokenRequest,
  RegressionAnalysisDashboardDataV2Dto,
  ResendEmailVerificationTokenRequest,
  ResetPasswordRequest,
  ScriptKindDtoValue,
  SendShareUrlDto,
  SetFlowLiveDemoDto,
  ShareUrl,
  SigninDto,
  SignInResDto,
  SignupRequest,
  SignupRequestFreeTrial,
  SignupRequestPsLite,
  SliceSuggestionsReq,
  StartFlowRunDto,
  TeamDto,
  TeamProjectsSummaryDto,
  TeamReq,
  TeamRoleApiValue,
  TeamsArray,
  TeamUserDto,
  TeamUsersResponse,
  Trace,
  TraceDownloadUrlDto,
  TraceDto,
  TracePageParams,
  TraceReq,
  Traces,
  TraceSettingsDto,
  TraceVideoMetadataDto,
  UpdateAnnotationDto,
  UpdateFlowAutomationConfigDto,
  UpdateFlowDto,
  UpdateTeamDto,
  UpdateTraceLocalFlagDto,
  UploadBuildDto,
  UserIdReq,
  UserQuestionnaireDto,
  UserSettingsDto,
} from './models'

import {
  CopyTraceDto,
  IssuerDto,
  TraceAndUploadUrlDto,
  UploadRequestSpecDto,
} from './__generated__/api-types'

const NO_AIRTABLE_PROJECT_STATUS_CODE = 409
const NO_AIRTABLE_CONNECTION_RECORD_CODE = 404

const SIGN_IN = '/sign-in'
const SIGN_UP = '/sign-up'
const SIGN_UP_SSO = '/sign-up-sso'
const SIGN_UP_CHECK = `${SIGN_UP}/check`
const INVITE_WHITELIST = '/request-invite'
const RECOVERY_TOKEN = '/recovery-token'
const RESET_PASSWORD = '/reset-password'
const RESET_PASSWORD_CHECK = `${RESET_PASSWORD}/check`
const REQUEST_FREE_TRIAL = '/request-free-trial'
const SIGN_UP_FREE_TRIAL = '/sign-up-free-trial'
const REQUEST_PS_LITE = '/request-ps-lite'
const SIGN_UP_PS_LITE = '/sign-up-ps-lite'

export class Api {
  readonly host: string

  private readonly path: string

  private readonly axios: Axios

  private originalMethods: Partial<this> = {}

  constructor(host: string, axios: Axios) {
    this.host = host
    this.path = `${host}${VERSION_PREFIX}`
    this.axios = axios
  }

  public overrideMethod<T extends keyof this>(methodName: T, callback: typeof this[T]): void {
    this.resetOverrideMethod(methodName)
    this.originalMethods[methodName] = this[methodName]
    this[methodName] = callback
  }

  public resetOverrideMethod<T extends keyof this>(methodName: T): void {
    const originalMethod = this.originalMethods[methodName]
    if (originalMethod) {
      this[methodName] = originalMethod as this[T] // You can remove this[T] after we migrate to TS 4.9.5
    }
  }

  urlsNotRequireAuth(): string[] {
    return [
      this.path + SIGN_IN,
      this.path + SIGN_UP,
      this.path + SIGN_UP_SSO,
      this.path + SIGN_UP_CHECK,
      this.path + INVITE_WHITELIST,
      this.path + RECOVERY_TOKEN,
      this.path + RESET_PASSWORD,
      this.path + RESET_PASSWORD_CHECK,
      this.path + REQUEST_FREE_TRIAL,
      this.path + SIGN_UP_FREE_TRIAL,
      this.path + REQUEST_PS_LITE,
      this.path + SIGN_UP_PS_LITE,
    ]
  }

  getAuthCheck(): Promise<void> {
    return this.axios
      .get<void, void>(`${this.path}/auth-check`)
      .catch((error) => this.rejectOnError(error))
  }

  postSignIn(data: SigninDto): Promise<SignInResDto> {
    return this.axios
      .post<SignInResDto>(`${this.path}${SIGN_IN}`, data)
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  postInviteWhitelist(data: InviteWhitelistReq): Promise<void> {
    return this.axios
      .post<void, void>(`${this.path}${INVITE_WHITELIST}`, data)
      .catch((error) => this.rejectOnError(error))
  }

  postSignUpCheckToken(data: CheckInviteTokenRequest): Promise<CheckInviteTokenResponse> {
    return this.axios
      .post<CheckInviteTokenResponse>(`${this.path}${SIGN_UP_CHECK}`, data)
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  postSignUp(data: SignupRequest): Promise<void> {
    return this.axios
      .post<void, void>(`${this.path}${SIGN_UP}`, data)
      .catch((error) => this.rejectOnError(error))
  }

  postRequestFreeTrial(data: FreeTrialRequest): Promise<void> {
    return this.axios
      .post<void, void>(`${this.path}${REQUEST_FREE_TRIAL}`, data)
      .catch((error) => this.rejectOnError(error))
  }

  postSignUpFreeTrial(data: SignupRequestFreeTrial): Promise<void> {
    return this.axios
      .post<void, void>(`${this.path}${SIGN_UP_FREE_TRIAL}`, data)
      .catch((error) => this.rejectOnError(error))
  }

  postRequestPsLite(data: PsLiteRequest): Promise<void> {
    return this.axios
      .post<void, void>(`${this.path}${REQUEST_PS_LITE}`, data)
      .catch((error) => this.rejectOnError(error))
  }

  postSignUpPsLite(data: SignupRequestPsLite): Promise<void> {
    return this.axios
      .post<void, void>(`${this.path}${SIGN_UP_PS_LITE}`, data)
      .catch((error) => this.rejectOnError(error))
  }

  postRecoveryToken(data: RecoveryTokenRequest): Promise<void> {
    return this.axios
      .post<void, void>(`${this.path}${RECOVERY_TOKEN}`, data)
      .catch((error) => this.rejectOnError(error))
  }

  postResetPasswordCheckToken(data: CheckRecoveryTokenRequest): Promise<void> {
    return this.axios
      .post<void, void>(`${this.path}${RESET_PASSWORD_CHECK}`, data)
      .catch((error) => this.rejectOnError(error))
  }

  postResetPassword(data: ResetPasswordRequest): Promise<void> {
    return this.axios
      .post<void, void>(`${this.path}${RESET_PASSWORD}`, data)
      .catch((error) => this.rejectOnError(error))
  }

  postSignOut(): Promise<void> {
    return this.axios
      .post<void, void>(`${this.path}/sign-out`)
      .catch((error) => this.rejectOnError(error))
  }

  postImages(formData: FormData, config?: AxiosRequestConfig<FormData>): Promise<ImageDataDto> {
    return this.axios
      .post<ImageDataDto>(`${this.path}/images`, formData, config)
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  getUser(abortSignal?: AbortSignal): Promise<AuthenticatedUserDto> {
    return this.axios
      .get<AuthenticatedUserDto>(getUserUrl(), { signal: abortSignal })
      .then((resp) => resp.data)
      .then((user) => {
        pendo.initialize({
          visitor: {
            id: user.email,
          },
          account: {
            id: 'Product Science',
          },
        })
        return user
      })
      .catch((error) => this.rejectOnError(error))
  }

  getTeams(abortSignal?: AbortSignal): Promise<TeamDto[]> {
    return this.axios
      .get<TeamsArray>(getTeamsUrl(), { signal: abortSignal })
      .then((resp) =>
        resp.data.sort((a, b) =>
          a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base',
          }),
        ),
      )
      .catch((error) => this.rejectOnError(error))
  }

  postTeam(data: CreateTeamDto): Promise<TeamDto> {
    return this.axios
      .post<TeamDto>(getTeamsUrl(), data)
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  putTeam(req: TeamReq, data: UpdateTeamDto): Promise<TeamDto> {
    return this.axios
      .put<TeamDto>(`${this.path}/teams/${req.teamUrlName}`, data)
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  deleteTeam(req: TeamReq): Promise<void> {
    return this.axios
      .delete<void, void>(`${this.path}/teams/${req.teamUrlName}`)
      .catch((error) => this.rejectOnError(error))
  }

  getTeamUsers(req: TeamReq): Promise<TeamUsersResponse> {
    return this.axios
      .get<TeamUsersResponse>(`${this.path}/teams/${req.teamUrlName}/users`)
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  postTeamUser(req: TeamReq, data: InviteUserToTeamDto): Promise<TeamUserDto> {
    return this.axios
      .post<TeamUserDto>(`${this.path}/teams/${req.teamUrlName}/users`, data)
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  putTeamUserRole(req: TeamReq & UserIdReq, data: { role: TeamRoleApiValue }): Promise<void> {
    return this.axios
      .put<void, void>(`${this.path}/teams/${req.teamUrlName}/users/${req.userId}/role`, data)
      .catch((error) => this.rejectOnError(error))
  }

  postTeamUserResend(req: TeamReq & UserIdReq): Promise<TeamUserDto> {
    return this.axios
      .post<TeamUserDto>(`${this.path}/teams/${req.teamUrlName}/users/${req.userId}/resend-invite`)
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  deleteTeamUser(req: TeamReq & UserIdReq): Promise<void> {
    return this.axios
      .delete<void, void>(`${this.path}/teams/${req.teamUrlName}/users/${req.userId}/role`)
      .catch((error) => this.rejectOnError(error))
  }

  getProject(req: ProjectReq): Promise<ProjectDto> {
    return this.axios
      .get<ProjectDto>(getProjectUrl(req.projectUrlName!))
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  getProjectsSummary(
    teamUrlName: string,
    abortSignal?: AbortSignal,
  ): Promise<TeamProjectsSummaryDto> {
    return this.axios
      .get<TeamProjectsSummaryDto>(getProjectsSummaryUrl(teamUrlName), { signal: abortSignal })
      .then((resp) => {
        const projectsSummary = resp.data
        projectsSummary.projects.sort((a, b) =>
          a.project.name.localeCompare(b.project.name, undefined, {
            numeric: true,
            sensitivity: 'base',
          }),
        )
        projectsSummary.projects.forEach((project) => {
          project.flows.sort((a, b) => a.projectLocalId - b.projectLocalId)
        })
        return projectsSummary
      })
  }

  postProject(req: TeamReq, data: CreateProjectDto): Promise<ProjectDto> {
    return this.axios
      .post<ProjectDto>(`${this.path}/teams/${req.teamUrlName}/projects`, data)
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  putProject(req: ProjectReq, data: CreateProjectDto): Promise<ProjectDto> {
    return this.axios
      .put<ProjectDto>(`${this.path}/projects/${req.projectUrlName}`, data)
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  deleteProject(req: ProjectReq): Promise<void> {
    return this.axios
      .delete<void, void>(`${this.path}/projects/${req.projectUrlName}`)
      .catch((error) => this.rejectOnError(error))
  }

  postProjectFavorite(data: { id: ProjectDto['id'] }): Promise<UserSettingsDto> {
    return this.axios
      .post<UserSettingsDto>(`${this.path}/user/projects/favorites`, data)
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  deleteProjectFavorite(req: { id: ProjectDto['id'] }): Promise<UserSettingsDto> {
    return this.axios
      .delete<UserSettingsDto>(`${this.path}/user/projects/favorites/${req.id}`)
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  getProjectUsers(req: ProjectReq): Promise<ProjectUsersResponse> {
    return this.axios
      .get<ProjectUsersResponse>(`${this.path}/projects/${req.projectUrlName}/users`)
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  postProjectUser(req: ProjectReq, data: InviteUserToProjectDto): Promise<ProjectUserDto> {
    return this.axios
      .post<ProjectUserDto>(`${this.path}/projects/${req.projectUrlName}/users`, data)
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  requestProjectAccess(request: ProjectAccessRequest) {
    return this.axios.post<unknown, unknown, ProjectAccessRequest>(
      `${this.path}/request-project-access`,
      request,
    )
  }

  postProjectUserResend(req: ProjectReq & UserIdReq): Promise<ProjectUserDto> {
    return this.axios
      .post<ProjectUserDto>(
        `${this.path}/projects/${req.projectUrlName}/users/${req.userId}/resend-invite`,
      )
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  putProjectUserRole(
    req: ProjectReq & UserIdReq,
    data: { role: ProjectRoleApiValue },
  ): Promise<void> {
    return this.axios
      .put<void, void>(`${this.path}/projects/${req.projectUrlName}/users/${req.userId}/role`, data)
      .catch((error) => this.rejectOnError(error))
  }

  deleteProjectUser(req: ProjectReq & UserIdReq): Promise<void> {
    return this.axios
      .delete<void, void>(`${this.path}/projects/${req.projectUrlName}/users/${req.userId}/role`)
      .catch((error) => this.rejectOnError(error))
  }

  getFlows(req: ProjectReq): Promise<Flows> {
    return this.axios
      .get<Flows>(`${this.path}/projects/${req.projectUrlName}/flows`)
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  postFlowFavorite(req: FlowReq): Promise<void> {
    return this.axios
      .post<void, void>(
        `${this.path}/projects/${req.projectUrlName}/flows/favorites/${req.flowProjectLocalId}`,
      )
      .catch((error) => this.rejectOnError(error))
  }

  deleteFlowFavorite(req: FlowReq): Promise<void> {
    return this.axios
      .delete<void, void>(
        `${this.path}/projects/${req.projectUrlName}/flows/favorites/${req.flowProjectLocalId}`,
      )
      .catch((error) => this.rejectOnError(error))
  }

  postFlowSubscribe(req: FlowReq): Promise<void> {
    return this.axios
      .post<void, void>(
        `${this.path}/projects/${req.projectUrlName}/flows/subscriptions/${req.flowProjectLocalId}`,
      )
      .catch((error) => this.rejectOnError(error))
  }

  deleteFlowSubscribe(req: FlowReq): Promise<void> {
    return this.axios
      .delete<void, void>(
        `${this.path}/projects/${req.projectUrlName}/flows/subscriptions/${req.flowProjectLocalId}`,
      )
      .catch((error) => this.rejectOnError(error))
  }

  getUnassignedTraces(req: ProjectReq): Promise<Traces> {
    return this.axios
      .get<Traces>(`${this.path}/projects/${req.projectUrlName}/traces`)
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  getFlow(req: FlowReq): Promise<FlowDto> {
    return this.axios
      .get<FlowDto>(getFlowUrl(req))
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  putFlow(req: FlowReq, data: UpdateFlowDto): Promise<FlowDto> {
    return this.axios
      .put<FlowDto>(
        `${this.path}/projects/${req.projectUrlName}/flows/${req.flowProjectLocalId}`,
        data,
      )
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  getFlowTraces({ projectUrlName, flowProjectLocalId }: FlowReq): Promise<Traces> {
    return this.axios
      .get<Traces>(`${this.path}/projects/${projectUrlName}/flows/${flowProjectLocalId}/traces`)
      .then((resp) => resp.data)
  }

  async postTraceVideo(
    req: TracePageParams,
    formData: FormData,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
    abortSignal?: AbortSignal,
  ): Promise<TraceVideoMetadataDto> {
    const config: AxiosRequestConfig<FormData> = {
      onUploadProgress,
      signal: abortSignal,
    }
    const response = await this.axios.post<TraceVideoMetadataDto>(
      `${this.path}/projects/${req.projectUrlName}/traces/${req.traceProjectLocalId}/video`,
      formData,
      config,
    )
    return response.data
  }

  getTraceVideoMetadata({
    projectUrlName,
    traceProjectLocalId,
  }: {
    projectUrlName: string
    traceProjectLocalId: string
  }): Promise<TraceVideoMetadataDto | null> {
    return this.axios
      .get<TraceVideoMetadataDto>(getTraceVideoMetadataUrl(projectUrlName, traceProjectLocalId))
      .then((resp) => resp.data)
      .catch((reason) => {
        const axiosError = reason as AxiosError
        if (axiosError.isAxiosError && axiosError?.response?.status === 404) {
          return Promise.resolve(null)
        }
        return Promise.reject(reason)
      })
  }

  async getTraceVideo(
    url: string,
    onDownloadProgress: (progressEvent: AxiosProgressEvent) => void,
  ): Promise<Blob> {
    const result = await this.axios.get<Blob>(url, {
      headers: {
        Authorization: null,
      },
      responseType: 'blob',
      onDownloadProgress,
    })
    return result.data
  }

  deleteTraceVideo(req: TracePageParams): Promise<void> {
    return this.axios.delete(
      `${this.path}/projects/${req.projectUrlName}/traces/${req.traceProjectLocalId}/video`,
    )
  }

  getTrace(req: TracePageParams): Promise<TraceDataDto> {
    return this.getTraceUrl(req).then(({ url }) => {
      return this.getTraceByUrl(url)
    })
  }

  getTraceUrl(req: TracePageParams): Promise<TraceDownloadUrlDto> {
    return this.axios
      .get<TraceDownloadUrlDto>(getTraceFileUrl(req.projectUrlName, req.traceProjectLocalId))
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  getTraceByUrl(url: string): Promise<TraceDataDto> {
    return this.axios
      .get<TraceDataDto>(url, {
        headers: {
          Authorization: null,
        },
      })
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  getSingleTrace(req: TracePageParams): Promise<Trace> {
    return this.axios
      .get<Trace>(getTraceEntityUrl(req))
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  updateTraceTitle(req: TracePageParams, title: string): Promise<Trace> {
    const url = `${this.path}/projects/${req.projectUrlName}/traces/${req.traceProjectLocalId}`
    return this.axios
      .patch<Trace>(url, { name: title })
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  getNamedLinks(req: Required<ProjectReq>): Promise<NamedLinkDto[]> {
    return this.axios
      .get<NamedLinkDto[]>(getNamedLinksUrl(req.projectUrlName))
      .then((resp) => resp.data)
      .catch((error) => {
        if (error.response?.status === NO_AIRTABLE_PROJECT_STATUS_CODE) {
          //TODO: should we disable the links creation in this case?
          //TODO: show an error msg (let's implemented when the toasts changes are merged)
          console.warn(error.response?.data?.message)
          return []
        } else {
          return this.rejectOnError(error)
        }
      })
  }

  getTraceConnections(
    projectIdOrUrlName: string,
    traceProjectLocalId: string,
  ): Promise<GetConnectionsDto> {
    return this.axios
      .get<GetConnectionsDto>(
        getTraceConnectionsUrl({ projectUrlName: projectIdOrUrlName, traceProjectLocalId }),
      )
      .then((resp) => resp.data)
  }

  postNamedLink(projectUrlName: string, data: CreateNamedLinkDto): Promise<NamedLinkDto> {
    return this.axios
      .post<NamedLinkDto>(`${this.path}/projects/${projectUrlName}/named-links`, data)
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  deleteNamedLink(projectUrlName: string, id: string): Promise<void> {
    return this.axios
      .delete<void, void>(`${this.path}/projects/${projectUrlName}/named-links/${id}`)
      .catch((error) => {
        if (error.response?.status === NO_AIRTABLE_CONNECTION_RECORD_CODE) {
          //TODO: show an error msg (let's implemented when the toasts changes are merged)
          console.warn(error.response?.data?.message)
        } else {
          return this.rejectOnError(error)
        }
      })
  }

  postFlow(req: ProjectReq, data: CreateFlowDto): Promise<FlowDto> {
    return this.axios
      .post<FlowDto>(`${this.path}/projects/${req.projectUrlName}/flows`, data)
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  deleteFlow({
    projectUrlName,
    flowProjectLocalId,
    shouldDeleteWithTraces,
  }: {
    projectUrlName: string
    flowProjectLocalId: number
    shouldDeleteWithTraces: boolean
  }): Promise<void> {
    return this.axios.delete<void, void>(
      `${this.path}/projects/${projectUrlName}/flows/${flowProjectLocalId}`,
      {
        params: { withTraces: shouldDeleteWithTraces },
      },
    )
  }

  postFlowsRestore({
    projectUrlName,
    flowProjectLocalId,
  }: {
    projectUrlName: string
    flowProjectLocalId: number
  }): Promise<Flows> {
    return this.axios
      .post<Flows>(`${this.path}/projects/${projectUrlName}/flows/restore`, [flowProjectLocalId])
      .then((resp) => resp.data)
  }

  postFlowExecutionScript(req: FlowReq, data: FormData): Promise<FlowDto> {
    return this.axios
      .post<FlowDto>(
        `${this.path}/projects/${req.projectUrlName}/flows/${req.flowProjectLocalId}/executionScript`,
        data,
      )
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  postAssignTrace(req: TraceReq, flowProjectLocalIds: number[]): Promise<void> {
    return this.axios
      .post<void, void>(
        `${this.path}/projects/${req.projectUrlName}/traces/${req.traceProjectLocalId}`,
        flowProjectLocalIds,
      )
      .catch((error) => this.rejectOnError(error))
  }

  postUploadTrace(
    req: Required<FlowReq>,
    formData: FormData,
    config?: AxiosRequestConfig<FormData>,
  ): Promise<Trace> {
    return this.axios
      .post<Trace>(getFlowTracesUrl(req.projectUrlName, req.flowProjectLocalId), formData, config)
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  postTraceEntity(
    req: PostTraceEntity,
    body: CreateTraceDto,
    config: AxiosRequestConfig<CreateTraceDto>,
  ): Promise<TraceAndUploadUrlDto> {
    const { projectIdOrUrlName, flowProjectLocalId } = req
    const path = `projects/${projectIdOrUrlName}/flows/${flowProjectLocalId}/trace-upload-urls`
    return this.axios
      .post(`${this.path}/${path}`, body, config)
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  async uploadTraceToObjectStorage(
    file: File,
    reqConfig: UploadRequestSpecDto,
    onUploadProgress: (progressEvent: ProgressEvent) => void = () => {},
  ): Promise<Response> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()

      xhr.open(reqConfig.method, reqConfig.url)

      xhr.setRequestHeader('Content-Type', reqConfig.headers['Content-Type']!)
      xhr.setRequestHeader(
        'X-Goog-Content-Length-Range',
        reqConfig.headers['X-Goog-Content-Length-Range']!,
      )

      xhr.upload.addEventListener('progress', onUploadProgress)

      xhr.onload = function () {
        if (xhr.status === 200) {
          resolve(xhr.response)
        } else {
          reject(new Error(`Unexpected status code: ${xhr.status}`))
        }
      }

      xhr.onerror = function () {
        reject(new Error('An error occurred during the upload.'))
      }

      xhr.send(file)
    })
  }

  postTraceStartProcessing({
    projectIdOrUrlName,
    flowProjectLocalId,
    traceProjectLocalId,
  }: {
    projectIdOrUrlName: string
    flowProjectLocalId: string
    traceProjectLocalId: string
  }): Promise<void> {
    const path = `projects/${projectIdOrUrlName}/flows/${flowProjectLocalId}/traces/${traceProjectLocalId}/start-processing`

    return this.axios
      .post<void, void>(`${this.path}/${path}`)
      .catch((error) => this.rejectOnError(error))
  }

  deleteFlowTrace(req: FlowTraceReq): Promise<void> {
    return this.axios
      .delete<void, void>(
        `${this.path}/projects/${req.projectUrlName}/flows/${req.flowProjectLocalId}/traces/${req.traceProjectLocalId}`,
      )
      .catch((error) => this.rejectOnError(error))
  }

  deleteTrace(req: TraceReq): Promise<void> {
    return this.axios
      .delete<void, void>(
        `${this.path}/projects/${req.projectUrlName}/traces/${req.traceProjectLocalId}`,
      )
      .catch((error) => this.rejectOnError(error))
  }

  getFlags(req: Required<ChartPageParams>): Promise<Flag[]> {
    return this.axios
      .get<Flag[]>(getFlagsUrl(req))
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  postFlag(req: ChartPageParams, flag: Flag): Promise<Flag> {
    const flowId = req.flowProjectLocalId
    const traceId = req.traceProjectLocalId
    const timestamp = Date.now()
    // @ts-expect-error FIXME: cid can't be undefined
    const data: CreateTraceLocalFlagDto = { ...flag, timestamp: timestamp }
    return this.axios
      .post<Flag>(
        `${this.path}/projects/${req.projectUrlName}/flows/${flowId}/traces/${traceId}/flags`,
        data,
      )
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  deleteFlag(req: ChartPageParams, flag: Flag): Promise<void> {
    const flowId = req.flowProjectLocalId
    const traceId = req.traceProjectLocalId
    const proj = req.projectUrlName
    const flagDeleteDto: DeleteTraceLocalFlagDto = {
      id: flag.id > 0 ? flag.id : undefined,
      cid: flag.cid ? flag.cid : undefined,
      timestamp: Date.now(),
    }
    return this.axios
      .delete<void, void>(`${this.path}/projects/${proj}/flows/${flowId}/traces/${traceId}/flags`, {
        data: flagDeleteDto,
      })
      .catch((error) => this.rejectOnError(error))
  }

  putFlag(req: ChartPageParams, flag: Flag): Promise<Flag> {
    const flowId = req.flowProjectLocalId
    const traceId = req.traceProjectLocalId
    const data: UpdateTraceLocalFlagDto = {
      id: flag.id > 0 ? flag.id : undefined,
      cid: flag.cid ? flag.cid : undefined,
      color: typeof flag.color === 'number' ? flag.color : 0,
      time: flag.time,
      title: flag.title,
      source: flag.source,
      timestamp: Date.now(),
    }
    return this.axios
      .put<Flag>(
        `${this.path}/projects/${req.projectUrlName}/flows/${flowId}/traces/${traceId}/flags`,
        data,
      )
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  genAlternativeUiUrl(req: { projectUrlName?: string; traceProjectLocalId?: string }): string {
    return `${this.path}/projects/${req.projectUrlName}/traces/${req.traceProjectLocalId}/alternative-ui`
  }

  rejectOnError<T = never>(axiosError: AxiosError): Promise<T> {
    return Promise.reject(axiosError)
  }

  getChoreographerPaths(params: TracePageParams): Promise<TraceSettingsDto> {
    return this.axios
      .get<TraceSettingsDto>(getTraceSettingsUrl(params))
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  putChoreographerPaths(req: ChoreographerReq, paths: TraceSettingsDto): Promise<TraceSettingsDto> {
    return this.axios
      .put<TraceSettingsDto>(getTraceSettingsUrl(req), paths)
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  getAnnotations(req: ChartPageParams): Promise<AnnotationDto[]> {
    return this.axios
      .get<AnnotationDto[]>(getAnnotationsUrl(req))
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  postAnnotation(req: ChartPageParams, annotation: CreateAnnotationDto): Promise<AnnotationDto> {
    const flowId = req.flowProjectLocalId
    const traceId = req.traceProjectLocalId
    annotation.timestamp = Date.now()
    return this.axios
      .post<AnnotationDto>(
        `${this.path}/projects/${req.projectUrlName}/flows/${flowId}/traces/${traceId}/annotations`,
        annotation,
      )
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  deleteAnnotation(req: ChartPageParams, annotation: AnnotationDto): Promise<void> {
    const flowId = req.flowProjectLocalId
    const traceId = req.traceProjectLocalId
    const url = `${this.path}/projects/${req.projectUrlName}/flows/${flowId}/traces/${traceId}/annotations`
    const params = `id=${annotation.id}&cid=${annotation.cid}&timestamp=${Date.now()}`
    return this.axios
      .delete<void, void>(`${url}?${params}`)
      .catch((error) => this.rejectOnError(error))
  }

  putAnnotation(req: ChartPageParams, annotation: UpdateAnnotationDto): Promise<AnnotationDto> {
    const flowId = req.flowProjectLocalId
    const traceId = req.traceProjectLocalId
    annotation.timestamp = Date.now()
    const url = `${this.path}/projects/${req.projectUrlName}/flows/${flowId}/traces/${traceId}/annotations`
    return this.axios
      .put<AnnotationDto>(url, annotation)
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  putUserQuestionnaire(data: UserQuestionnaireDto): Promise<UserQuestionnaireDto> {
    return this.axios
      .put<UserQuestionnaireDto>(`${this.path}/user/questionnaire`, data)
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isCancelled(reason: any) {
    return isAxiosRequestCancelled(reason)
  }

  getTeamShareUrl(teamUrlName: string): Promise<ShareUrl> {
    return this.axios.get(getShareUrlUrl(teamUrlName)).then((res) => res.data)
  }

  getProjectShareUrl(projectIdOrUrlName: string): Promise<ShareUrl> {
    return this.axios
      .get(`${this.path}/projects/${projectIdOrUrlName}/share-url`)
      .then((res) => res.data)
  }

  postTeamShareUrl(teamUrlName: string, emails: string[]) {
    return this.axios.post(`${getShareUrlUrl(teamUrlName)}/send`, {
      emails,
    } as SendShareUrlDto)
  }

  postProjectShareUrl(projectIdOrUrlName: string, emails: string[]) {
    return this.axios.post(`${this.path}/projects/${projectIdOrUrlName}/send`, {
      emails,
    } as SendShareUrlDto)
  }

  createFreeTrialProject(teamUrlName: string, data: CreateFreeTrialOnboardingRequestDto) {
    return this.axios.post(`${this.path}/teams/${teamUrlName}/free-trial/projects`, data)
  }

  submitFreeTrialOnboardingRequest(
    teamUrlName: string,
    data: CreateFreeTrialOnboardingRequestDto,
  ): Promise<FreeTrialOnboardingRequestDto> {
    return this.axios.post(getOnboardingRequestsUrl(teamUrlName), data).then((res) => res.data)
  }

  getFreTrialOnboardingRequests(teamUrlName: string): Promise<FreeTrialOnboardingRequestDto[]> {
    return this.axios.get(getOnboardingRequestsUrl(teamUrlName)).then((res) => res.data)
  }

  postBuildProperties(projectUrlName: string): Promise<string> {
    return this.axios
      .post<string>(getBuildPropertiesUrl(projectUrlName))
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  getAndroidPluginVersions(): Promise<AndroidPluginVersionsDto> {
    return this.axios
      .get<AndroidPluginVersionsDto>(getAndroidPluginVersionsUrl())
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  getAppBuildAndRunStatus(projectUrlName: string): Promise<InstructionsStateDto> {
    return this.axios
      .get<InstructionsStateDto>(getInstructionsStateUrl(projectUrlName))
      .then((res) => res.data)
  }

  postFinishInstructions(projectIdOrUrlName: string) {
    return this.axios.post(`${this.path}/projects/${projectIdOrUrlName}/finish-instructions`)
  }

  postResendVerToken(request: ResendEmailVerificationTokenRequest) {
    if (request.email == null && request.inviteToken == null) {
      throw new Error(
        'The request has to have at least one of the fields "email" or "inviteToken" not empty',
      )
    }
    return this.axios.post(`${this.path}/sign-up/resend-ver-token`, request)
  }

  postJumpToSource(
    req: JumpToSourceRequestDto & { projectUrlName: string; traceProjectLocalId: string },
  ) {
    const { projectUrlName, traceProjectLocalId, ...rest } = req
    return this.axios
      .post<JumpToSourceRequestDto>(
        `${this.path}/projects/${projectUrlName}/traces/${traceProjectLocalId}/navigate-to-slice`,
        rest,
      )
      .then((res) => res.data)
  }

  getSliceSuggestions(request: SliceSuggestionsReq): Promise<AnnotationSliceSuggestionDto[]> {
    const url = `${this.path}/projects/${request.projectUrlName}/flows/${request.flowProjectLocalId}/traces/${request.traceProjectLocalId}/annotations/slice-suggestions`
    return this.axios
      .get<AnnotationSliceSuggestionDto[]>(url)
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  getFlowComputation(request: FlowComputationReq): Promise<ComputationView> {
    const url = `${this.path}/projects/${request.projectUrlName}/flows/${request.flowProjectLocalId}/computations/SLICE_MATCHING`
    return this.axios
      .get<ComputationView>(url)
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  postSubmitFlowToProcessing = (request: FlowComputationReq): Promise<undefined> => {
    const url = `${this.path}/projects/${request.projectUrlName}/flows/${request.flowProjectLocalId}/airflow-process-flow`
    return this.axios
      .post<undefined>(url)
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  postSubmitFilterTraces = (request: FlowComputationReq): Promise<undefined> => {
    const url = `${this.path}/projects/${request.projectUrlName}/flows/${request.flowProjectLocalId}/airflow-filter-traces`
    return this.axios.post<undefined>(url).then((res) => res.data)
  }

  convertOktaToken = (oktaToken: string): Promise<OktaSignInResponse> => {
    const url = `${this.path}/okta-token-to-firebase`
    return this.axios
      .post<OktaSignInResponse>(url, { jwtToken: oktaToken })
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  postSupportIssue(req: ApkSupportIssueReq): Promise<ApkSupportIssueDtoOut> {
    return this.axios.postForm(`${this.path}/support`, req).then((res) => res.data)
  }

  postExtensionRequest(teamUrlName: string): Promise<FreeTrialExtensionDto> {
    return this.axios.post(getExtensionRequestUrl(teamUrlName)).then((res) => res.data)
  }

  getExtensionRequest(teamUrlName: string): Promise<FreeTrialExtensionDto> {
    return this.axios.get(getExtensionRequestUrl(teamUrlName)).then((res) => res.data)
  }

  postCopyTrace(
    sourceProjectUrlName: string,
    sourceTraceProjectLocalId: string,
    copyTraceDto: CopyTraceDto,
  ): Promise<Trace> {
    return this.axios
      .post(
        `${this.path}/projects/${sourceProjectUrlName}/traces/${sourceTraceProjectLocalId}/copy`,
        copyTraceDto,
      )
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  getOktaIssuer(email: string): Promise<IssuerDto> {
    return this.axios
      .get<IssuerDto>(`${this.path}/okta-issuer`, { params: { email } })
      .then((resp) => resp.data)
      .catch((reason) => {
        const axiosError = reason as AxiosError
        if (axiosError.isAxiosError && axiosError?.response?.status === 404) {
          return Promise.reject('components.oktaModal.noIssuerError')
        }
        return this.rejectOnError(reason)
      })
  }

  getRegressionAnalysisData(
    projectIdOrUrlName: string,
    flowProjectLocalId: number,
  ): Promise<RegressionAnalysisDashboardDataV2Dto> {
    return this.axios
      .get(
        `${this.path}/projects/${projectIdOrUrlName}/flows/${flowProjectLocalId}/regression-analysis/runs-v2`,
      )
      .then((res) => res.data)
  }

  postRegressionAnalysisRun(
    { projectUrlName, flowProjectLocalId }: FlowReq,
    body: StartFlowRunDto,
  ): Promise<RegressionAnalysisDashboardDataV2Dto> {
    return this.axios
      .post(
        `${this.path}/projects/${projectUrlName}/flows/${flowProjectLocalId}/regression-analysis/runs-v2`,
        body,
      )
      .then((res) => res.data)
  }

  postMetrics(request: MetricsDto) {
    return this.axios.post(getMetricsUrl(), request)
  }

  getAllProjects(): Promise<ProjectDto[]> {
    return this.axios.get(getAllProjectsUrl()).then((res) => res.data)
  }

  getGlobalLiveDemo(): Promise<GlobalLiveDemoDto> {
    return this.axios
      .get(getGlobalLiveDemoUrl())
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  createLiveDemoContent(traceParams: ChartPageParams): Promise<TraceDto> {
    return this.axios
      .post(
        `${this.path}/projects/${traceParams.projectUrlName}/flows/${traceParams.flowProjectLocalId}/traces/${traceParams.traceProjectLocalId}/content`,
      )
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  assignLiveDemoTraceToFlow(
    targetFlow: Required<FlowReq>,
    sourceTrace: SetFlowLiveDemoDto,
  ): Promise<TraceDto> {
    return this.axios
      .post(
        `${this.path}/projects/${targetFlow.projectUrlName}/flows/${targetFlow.flowProjectLocalId}/live-demo`,
        sourceTrace,
      )
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  removeLiveDemoAssignment(targetFlow: Required<FlowReq>): Promise<TraceDto> {
    return this.axios
      .delete(
        `${this.path}/projects/${targetFlow.projectUrlName}/flows/${targetFlow.flowProjectLocalId}/live-demo`,
      )
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  postProjectBuild(
    projectIdOrUrlName: string,
    body: UploadBuildDto,
  ): Promise<BuildAndUploadRequestSpecDto> {
    return this.axios
      .post(`${getProjectUrl(projectIdOrUrlName)}/builds`, body)
      .then((resp) => resp.data)
  }

  getProjectBuilds(projectIdOrUrlName: string): Promise<BuildDto[]> {
    return this.axios.get(`${getProjectUrl(projectIdOrUrlName)}/builds`).then((res) => res.data)
  }

  getDevices(projectIdOrUrlName: string): Promise<DevicesDto> {
    return this.axios
      .get(`${getProjectUrl(projectIdOrUrlName)}/devices`)
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  putRunConfig(req: FlowReq, data: UpdateFlowAutomationConfigDto): Promise<FlowDto> {
    return this.axios
      .put<FlowDto>(
        `${this.path}/projects/${req.projectUrlName}/flows/${req.flowProjectLocalId}/runConfig`,
        data,
      )
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  postFlowScript(req: FlowReq, file: File, kind: ScriptKindDtoValue): Promise<FlowDto> {
    const data = new FormData()
    data.append('scriptfile', file)
    data.append('kind', kind)
    return this.axios
      .post<FlowDto>(
        `${this.path}/projects/${req.projectUrlName}/flows/${req.flowProjectLocalId}/scripts`,
        data,
      )
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  postBuildUploads(projectIdOrUrlName: string): Promise<BuildUploadContextDto> {
    return this.axios
      .post<BuildUploadContextDto>(`${this.path}/projects/${projectIdOrUrlName}/build-uploads`)
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }

  getRegressionNotifications(projectIdOrUrlName: string): Promise<RaUserNotificationConfigDto> {
    return this.axios
      .get(`${this.path}/projects/${projectIdOrUrlName}/regression-analysis/settings/notifications`)
      .then((res) => res.data)
      .catch((error) => this.rejectOnError(error))
  }

  postRegressionNotifications(
    projectIdOrUrlName: string,
    config: RaUserNotificationConfigDto,
  ): Promise<RaUserNotificationConfigDto> {
    return this.axios
      .post<RaUserNotificationConfigDto>(
        `${this.path}/projects/${projectIdOrUrlName}/regression-analysis/settings/notifications`,
        config,
      )
      .then((resp) => resp.data)
      .catch((error) => this.rejectOnError(error))
  }
}
