/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useCallback, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import styled from 'styled-components/macro'
import { TickFormatter } from '@visx/axis'
import { NumberValue } from 'd3-scale'
import tw from 'twin.macro'
import { LineGraph } from 'components/cco/LineGraph'
import { TableStateToggleButton, TableSummaryView } from 'components/cco/TableStateToggleButton'
import { EndpointsSearch } from 'components/cco/EndpointsSearch'
import { Link } from 'react-router-dom'

import { useCcoParams } from 'components/cco/useCcoParams'

import {
  ChartData,
  SummaryDto,
  MethodData,
  SessionData,
  DashboardData,
  EndpointData,
  EndpointSessionData,
  SessionEndpointData,
  MethodType,
} from 'components/cco/types'

function defaultSortUsingSummary(
  { cpuTime: cpuTimeX }: SummaryDto,
  { cpuTime: cpuTimeY }: SummaryDto,
): number {
  return cpuTimeY - cpuTimeX // Descending order
}

const stopMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
  event.stopPropagation()
}

export const StatsView = ({
  processFile,
  dashboardData,
  sessionData,
  summaryViewMode,
  search,
  onSummaryViewChange,
  onSearchChange,
}: {
  processFile: (fileEvent: ChangeEvent<HTMLInputElement>) => void
  dashboardData: DashboardData
  sessionData: SessionData | null
  summaryViewMode: TableSummaryView
  onSummaryViewChange: (view: TableSummaryView) => void
  search: string
  onSearchChange: (input: string) => void
}) => {
  const showChartsBoard = sessionData === null
  return (
    <div className="relative w-full min-w-[900px] min-h-full bg-dark-default">
      <div className="px-[24px]">
        <Application processFile={processFile} />
      </div>
      {showChartsBoard && (
        <>
          <div className="px-[24px] h-[64px] flex items-center">
            <span className="text-[14px] leading-[19px] font-semibold">Dashboard</span>
          </div>
          <div className="px-[24px] border-y-[1px] border-y-[#383838]">
            <ChartsBoard graphStats={dashboardData.chartsData} />
          </div>
        </>
      )}

      <div className="px-[24px]">
        <Table
          endpoints={dashboardData.endpointsData}
          session={sessionData}
          search={search}
          summaryViewMode={summaryViewMode}
          onSearchChange={onSearchChange}
          onSummaryViewChange={onSummaryViewChange}
        />
      </div>
    </div>
  )
}

const Application = ({
  processFile,
}: {
  processFile: (fileEvent: ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <div className="h-[96px] flex align-middle">
      <div className="flex items-center">
        <div className="h-[42px] w-[42px] flex justify-center items-center mr-[5px] bg-electro rounded-[4px]">
          <svg
            width="22"
            height="20"
            viewBox="0 0 22 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.36 0H1.32V4.78261H0V11.3043H1.32V20H22V13.4783H8.36V11.3043H22V4.78261H8.36V0Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="flex pl-[16px]">
          <div className="pr-[24px] text-[30px] leading-[32px] font-bold border-r-[1px] border-r-[#383838]">
            ProductScience
          </div>
          <div className="pl-[24px] text-[12px] leading-[16px] font-semibold">
            <span className="mb-[2px]">Web Application</span>
            <br />
            <span className="text-gray-service">Java/Kotlin</span>
          </div>
        </div>
        <div>
          <UploadFileInput className="hidden" onChange={processFile} />
        </div>
      </div>
    </div>
  )
}

const KB_THRESHOLD = 1024
const MB_THRESHOLD = 1024 * KB_THRESHOLD
const GB_THRESHOLD = 1024 * MB_THRESHOLD
const TB_THRESHOLD = 1024 * GB_THRESHOLD

const calculateCompute = (nanoseconds: number): number => {
  const nanosecondsPerMinute = 60 * 1e9
  const totalMinutes = nanoseconds / nanosecondsPerMinute
  const hours = Math.floor(totalMinutes / 60)
  return hours * 0.052
}

const calculateTraffic = (bytes: number): number => {
  return (bytes / GB_THRESHOLD) * 0.02
}

const calculateStorage = (bytes: number): number => {
  return (bytes / GB_THRESHOLD) * 0.24
}

const formatPrice = (price: number): string => {
  if (price < 0.01) {
    return 'less than a cent'
  }

  // Convert the number to a string with comma delimiters
  return price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
}

const formatNS = (nanoseconds: number, keepZero = false): string => {
  if (nanoseconds === 0 && !keepZero) {
    return '-'
  }

  const nanosecondsPerSecond = 1e9
  const nanosecondsPerMinute = 60 * nanosecondsPerSecond
  const totalMinutes = nanoseconds / nanosecondsPerMinute
  const hours = Math.floor(totalMinutes / 60)
  const minutes = Math.floor(totalMinutes % 60)
  const seconds = Math.floor((nanoseconds % nanosecondsPerMinute) / nanosecondsPerSecond)
  const milliseconds = Math.floor((nanoseconds % nanosecondsPerSecond) / 1e6)

  if (nanoseconds < 1e6) {
    return 'less than 1ms'
  }

  let formattedTime = ''

  // Format as "Mm Ss" if less than 10 minutes and no hours
  if (hours === 0 && totalMinutes < 10) {
    if (minutes > 0) {
      formattedTime += `${minutes} m `
    }
    if (seconds > 1) {
      formattedTime += `${seconds} s`
    }
    // Only add milliseconds if there are no minutes
    if (minutes === 0) {
      formattedTime += ` ${milliseconds} ms`
    }

    return formattedTime.trim() // Trim any extra space
  }

  // Format as "Hh Mm" if there are hours or 10 minutes or more
  if (hours > 0) {
    formattedTime += `${hours} h `
  }
  formattedTime += `${minutes} m`

  return formattedTime
}

function transformSummary(summary: SummaryDto, view: TableSummaryView): string[] {
  const { cpuTime, traffic, storage, count } = summary

  switch (view) {
    case TableSummaryView.TOTAL:
      return [formatNS(cpuTime), formatData(storage), formatData(traffic), String(count)]
    case TableSummaryView.AVERAGE:
      return [
        formatNS(cpuTime / count),
        formatData(storage / count),
        formatData(traffic / count),
        String(1),
      ]
    case TableSummaryView.COST:
      const computePrice = calculateCompute(cpuTime)
      const storagePrice = calculateStorage(storage)
      const trafficPrice = calculateTraffic(traffic)
      const totalPrice = computePrice + storagePrice + trafficPrice

      return [
        formatPrice(computePrice),
        formatPrice(storagePrice),
        formatPrice(trafficPrice),
        formatPrice(totalPrice),
      ]
  }

  return []
}

const yFormatCPU = (d: NumberValue) => {
  if (d === 0) {
    return ''
  }
  const nanoseconds = d as number
  return formatNS(nanoseconds)
}

const formatData = (sizeBytes: number, keepZero = false) => {
  if (sizeBytes === 0 && !keepZero) {
    return '-'
  }

  if (sizeBytes >= TB_THRESHOLD) {
    return `${(sizeBytes / TB_THRESHOLD).toFixed(2)} Tb`
  } else if (sizeBytes >= GB_THRESHOLD) {
    return `${(sizeBytes / GB_THRESHOLD).toFixed(2)} Gb`
  } else if (sizeBytes >= MB_THRESHOLD) {
    return `${(sizeBytes / MB_THRESHOLD).toFixed(2)} Mb`
  } else if (sizeBytes >= KB_THRESHOLD) {
    return `${(sizeBytes / KB_THRESHOLD).toFixed(2)} Kb`
  } else {
    return `${sizeBytes.toFixed(0)} b`
  }
}

const yFormatData = (d: NumberValue) => {
  // Check for 0 value and return empty string
  if (d === 0) {
    return ''
  }

  const sizeBytes = d as number
  return formatData(sizeBytes, true)
}

const yFormatActivity = (d: NumberValue) => {
  // Check for 0 value and return empty string
  if (d === 0) {
    return ''
  }
  return `${Math.floor(d as number)}`
}

const ChartsBoard = ({ graphStats }: { graphStats: ChartData[] }) => {
  return (
    <div className="grid down-1280:grid-cols-2 grid-cols-4 gap-[8px] py-[24px]">
      <LineChart
        title="CPU"
        data={graphStats}
        dataYFormat={yFormatCPU}
        lowColor="#009B8E"
        highColor="#C9FF2A"
      />
      <LineChart
        title="Storage"
        data={graphStats}
        dataYFormat={yFormatData}
        lowColor="#FB6500"
        highColor="#FFC600"
      />
      <LineChart
        title="Traffic"
        data={graphStats}
        dataYFormat={yFormatData}
        lowColor="#503CBB"
        highColor="#009EEE"
      />
      <LineChart
        title="Activity"
        data={graphStats}
        dataYFormat={yFormatActivity}
        lowColor="#671A9E"
        highColor="#B848E8"
      />
    </div>
  )
}

const LineChart = ({
  title,
  data,
  dataYFormat,
  lowColor,
  highColor,
}: {
  title: string
  data: ChartData[]
  dataYFormat: TickFormatter<NumberValue>
  lowColor: string
  highColor: string
}) => {
  return (
    <div className="h-[280px] bg-dark-dark2">
      <div className="h-[46px] px-[16px] flex items-center">
        <span className="text-[16px] leading-[22px] font-semibold">{title}</span>
      </div>
      <div className="h-[234px]">
        <LineGraph
          data={data}
          dataKey={title}
          dataXKey="x"
          dataYKey={`y${title}`}
          dataYFormat={dataYFormat}
          lowColor={lowColor}
          highColor={highColor}
        />
      </div>
    </div>
  )
}

const Table = ({
  endpoints,
  session,
  summaryViewMode,
  search,
  onSummaryViewChange,
  onSearchChange,
}: {
  endpoints: EndpointData[]
  session: SessionData | null
  summaryViewMode: TableSummaryView
  onSummaryViewChange: (view: TableSummaryView) => void
  search: string
  onSearchChange: (input: string) => void
}) => {
  const isSessionSelected = session !== null

  const filteredEndpoints = search.length
    ? endpoints.filter(({ title }) => title.toLowerCase().includes(search.toLowerCase()))
    : endpoints

  const filteredSessionEndpoints = search.length
    ? session?.endpoints.filter(({ title }, _) =>
        title.toLowerCase().includes(search.toLowerCase()),
      )
    : session?.endpoints

  return (
    <div className="pb-[24px]">
      {isSessionSelected && <TableSessionControls sessionId={session.title} />}
      <TableControls
        search={search}
        summaryViewMode={summaryViewMode}
        onSummaryViewChange={onSummaryViewChange}
        onSearchChange={onSearchChange}
      />
      <TableTitleRow
        type={session !== null ? TableTitleType.SESSION : TableTitleType.ENDPOINT}
        summaryViewMode={summaryViewMode}
      />
      {!isSessionSelected &&
        (filteredEndpoints.length > 0 ? (
          <TableEndpointList endpoints={filteredEndpoints} summaryViewMode={summaryViewMode} />
        ) : (
          <div className="h-[64px] flex items-center justify-center text-[16px] leading-[22px] font-semibold text-gray-service">
            Empty list
          </div>
        ))}
      {isSessionSelected && (
        <TableSessionCardContainer
          data={[session.title, ...transformSummary({ ...session }, summaryViewMode)]}
          endpoints={filteredSessionEndpoints ?? []}
          summaryViewMode={summaryViewMode}
        />
      )}
    </div>
  )
}

const TableControls = ({
  search,
  summaryViewMode,
  onSummaryViewChange,
  onSearchChange,
}: {
  summaryViewMode: TableSummaryView
  onSummaryViewChange: (view: TableSummaryView) => void
  search: string
  onSearchChange: (input: string) => void
}) => {
  return (
    <div className="h-[64px] flex items-center justify-between">
      <div>
        <TableStateToggleButton value={summaryViewMode} onChange={onSummaryViewChange} />
      </div>
      <div>
        <EndpointsSearch value={search} onChange={onSearchChange} />
      </div>
    </div>
  )
}

const TableSessionControls = ({ sessionId }: { sessionId: string }) => {
  const { ccoPath, ccoSessionTracePath } = useCcoParams()
  return (
    <div className="h-[64px] border-b-[1px] border-y-[#383838] flex items-center justify-between">
      <Link to={ccoPath} className="flex justify-center items-center cursor-pointer">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#383838" />
          <path d="M14 8L10 12L14 16" stroke="#B3B3B3" />
        </svg>
        <div className="pl-[24px] text-[14px] leading-[19px] font-semibold">User Session</div>
      </Link>

      <Link
        to={ccoSessionTracePath(sessionId)}
        className="w-[148px] h-[32px] flex items-center justify-center text-[12px] leading-[16px] font-semibold rounded-[4px] border-[1px] border-gray-service cursor-pointer"
      >
        View session in trace
      </Link>
    </div>
  )
}

const ColumnText = styled.span<{ right: boolean }>`
  direction: ${({ right }) => (right ? 'rtl' : 'ltl')};
`

const TableRowContainer = ({
  data,
  containerClassName,
  firstTextClassName,
  otherTextClassName,
  onMouseDown = () => {},
  onMouseUp = () => {},
}: {
  data: string[]
  containerClassName: string
  firstTextClassName: string
  otherTextClassName: string
  onMouseUp: () => void
  onMouseDown: () => void
}) => {
  return (
    <div
      className={twMerge('px-[8px] mb-[2px] rounded-[4px]', 'grid grid-cols-8', containerClassName)}
    >
      {data.length &&
        data.map((item, index) => (
          <div
            key={index}
            className={`${index === 0 ? 'col-span-4 *:truncate' : 'col-span-1'} flex items-center`}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
          >
            <ColumnText
              className={twMerge('px-[8px]', index === 0 ? firstTextClassName : otherTextClassName)}
              right={index === 0}
            >
              {item}
            </ColumnText>
          </div>
        ))}
    </div>
  )
}

const TableEndpointCardContainer = ({
  data,
  methods,
  sessions,
  summaryViewMode,
  onMouseDown = () => {},
  onMouseUp = () => {},
}: {
  data: string[]
  methods: MethodData[]
  sessions: EndpointSessionData[]
  summaryViewMode: TableSummaryView
  onMouseUp: () => void
  onMouseDown: () => void
}) => {
  const { ccoSessionPath } = useCcoParams()

  const [methodsExpander, setMethodsExpander] = useState(1)
  const [sessionsExpander, setSessionsExpander] = useState(1)

  const expandMethods = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setMethodsExpander((prevExpander) => prevExpander + 1)
    },
    [setMethodsExpander],
  )

  const collapseMethods = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setMethodsExpander(1)
    },
    [setMethodsExpander],
  )

  const expandSessions = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setSessionsExpander((prevExpander) => prevExpander + 1)
    },
    [setSessionsExpander],
  )

  const collapseSessions = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setSessionsExpander(1)
    },
    [setSessionsExpander],
  )

  return (
    <div className="px-[8px] pb-[16px] mb-[2px] rounded-[4px] bg-dark-dark2">
      <div className="h-[56px] border-b-[1px] border-b-[#383838] grid grid-cols-8">
        {data.length &&
          data.map((item, index) => (
            <div
              key={index}
              className={`${
                index === 0 ? 'col-span-4 *:truncate' : 'col-span-1'
              } flex items-center`}
              onMouseDown={onMouseDown}
              onMouseUp={onMouseUp}
            >
              <ColumnText
                className={twMerge(
                  'px-[8px]',
                  index === 0
                    ? 'text-[16px] leading-[22px] font-semibold'
                    : 'text-[12px] leading-[17px]',
                )}
                right={index === 0}
              >
                {item}
              </ColumnText>
            </div>
          ))}
      </div>
      <>
        {methods
          .slice(0, 10 * methodsExpander)
          .map(({ title, cpuTime, storage, traffic, count, type }, index) => (
            <TableCardChild
              key={index}
              data={[
                title,
                ...transformSummary({ cpuTime, traffic, storage, count }, summaryViewMode),
              ]}
              methodType={type}
            >
              {methodsExpander > 1 &&
                index === Math.min(methods.length - 1, 10 * methodsExpander - 2) && (
                  <TableCardChildButton onClick={collapseMethods} onMouseDown={stopMouseDown}>
                    Collapse
                  </TableCardChildButton>
                )}
              {methods.length / 10 >= methodsExpander && index === 10 * methodsExpander - 1 && (
                <TableCardChildButton onClick={expandMethods} onMouseDown={stopMouseDown}>
                  Expand
                </TableCardChildButton>
              )}
            </TableCardChild>
          ))}
        {sessions
          .slice(0, 10 * sessionsExpander)
          .map(({ title, cpuTime, storage, traffic, count }, index) => (
            <TableCardChild
              key={index}
              data={[
                <Link to={ccoSessionPath(title)}>{title}</Link>,
                ...transformSummary({ cpuTime, traffic, storage, count }, summaryViewMode),
              ]}
              lastItem={index === Math.min(sessions.length - 1, 10 * sessionsExpander - 1)}
            >
              {index === 0 && (
                <span className="text-[12px] leading-[17px] font-semibold text-white">
                  User Sessions
                </span>
              )}
              {sessionsExpander > 1 &&
                index === Math.min(methods.length - 1, 10 * methodsExpander - 2) && (
                  <TableCardChildButton onClick={collapseSessions} onMouseDown={stopMouseDown}>
                    Collapse
                  </TableCardChildButton>
                )}
              {sessions.length / 10 >= sessionsExpander && index === 10 * sessionsExpander - 1 && (
                <TableCardChildButton onClick={expandSessions} onMouseDown={stopMouseDown}>
                  Expand
                </TableCardChildButton>
              )}
            </TableCardChild>
          ))}
      </>
    </div>
  )
}

const TableSessionEndpoint = ({
  endpoint,
  summaryViewMode,
}: {
  endpoint: SessionEndpointData
  summaryViewMode: TableSummaryView
}) => {
  const { title, directMethods, indirectMethods, ...endpointSummary } = endpoint
  const [methodsExpander, setMethodsExpander] = useState(1)
  const methods = [...directMethods, ...indirectMethods].sort(defaultSortUsingSummary)

  const expandMethods = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setMethodsExpander((prevExpander) => prevExpander + 1)
    },
    [setMethodsExpander],
  )

  const collapseMethods = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setMethodsExpander(1)
    },
    [setMethodsExpander],
  )

  return (
    <>
      <TableCardChild
        data={[title, ...transformSummary({ ...endpointSummary }, summaryViewMode)]}
      />
      {methods
        .slice(0, 10 * methodsExpander)
        .map(({ title: methodTitle, type, ...methodSummary }, methodIndex) => (
          <TableCardChild
            key={methodIndex}
            data={[methodTitle, ...transformSummary({ ...methodSummary }, summaryViewMode)]}
            methodType={type}
            sub
          >
            {methodsExpander > 1 &&
              methodIndex === Math.min(methods.length - 1, 10 * methodsExpander - 2) && (
                <TableCardChildButton onClick={collapseMethods} onMouseDown={stopMouseDown}>
                  Collapse
                </TableCardChildButton>
              )}
            {methods.length / 10 >= methodsExpander && methodIndex === 10 * methodsExpander - 1 && (
              <TableCardChildButton onClick={expandMethods} onMouseDown={stopMouseDown}>
                Expand
              </TableCardChildButton>
            )}
          </TableCardChild>
        ))}
    </>
  )
}

const TableSessionCardContainer = ({
  data,
  endpoints,
  summaryViewMode,
}: {
  data: string[]
  endpoints: SessionEndpointData[]
  summaryViewMode: TableSummaryView
}) => {
  return (
    <div className="px-[8px] pb-[16px] mb-[2px] rounded-[4px] bg-dark-dark2">
      <div className="h-[56px] border-b-[1px] border-b-[#383838] grid grid-cols-8">
        {data.length &&
          data.map((item, index) => (
            <div
              key={index}
              className={`${
                index === 0 ? 'col-span-4 *:truncate' : 'col-span-1'
              } flex items-center`}
            >
              <ColumnText
                className={twMerge(
                  'px-[8px]',
                  index === 0
                    ? 'text-[16px] leading-[22px] font-semibold'
                    : 'text-[12px] leading-[17px]',
                )}
                right={index === 0}
              >
                {item}
              </ColumnText>
            </div>
          ))}
      </div>
      {endpoints.map((sessionEndpoint, index) => (
        <TableSessionEndpoint
          key={index}
          endpoint={sessionEndpoint}
          summaryViewMode={summaryViewMode}
        />
      ))}
    </div>
  )
}

const MethodTypeIcon = ({ methodType }: { methodType: MethodType }) => {
  return (
    <div
      className={twMerge(
        'min-w-[52px] h-[16px] rounded-[4px]',
        'text-dark-dark5 text-[11px] leading-[15px] font-semibold text-center',
        methodType === MethodType.DIRECT && 'bg-[#54BEBE]',
        methodType === MethodType.INDIRECT && 'bg-[#A66CCA]',
      )}
    >
      {methodType === MethodType.DIRECT ? 'Direct' : 'Indirect'}
    </div>
  )
}

const TableCardChildButton = styled.div`
  ${tw`w-[63px] h-[24px] rounded-[20px] border-[1px] border-[#B3B3B3] text-[12px] leading-[15px] flex justify-center items-center`}
`

const TableCardChild = ({
  data,
  methodType,
  lastItem = false,
  sub = false,
  children,
}: {
  data: React.ReactNode[]
  methodType?: MethodType
  lastItem?: boolean
  sub?: boolean
  children?: React.ReactNode
}) => {
  return (
    <div className={twMerge('h-[40px]', 'grid grid-cols-8')}>
      <>
        {sub && <div className={twMerge('px-[8px]', 'col-span-1', 'flex items-center')} />}
        <div className={twMerge('px-[8px]', 'col-span-1', 'flex items-center')}>{children}</div>
      </>
      {data.length &&
        data.map((item, index) => (
          <div
            key={index}
            className={twMerge(
              index === 0 && !sub && 'col-span-3 *:truncate',
              index === 0 && sub && 'col-span-2 *:truncate',
              index > 0 && 'col-span-1',
              !lastItem && 'border-b-[1px] border-b-[#383838]',
              'flex items-center',
            )}
          >
            {methodType !== undefined && index === 0 && <MethodTypeIcon methodType={methodType} />}
            <ColumnText
              className={twMerge('px-[8px]', 'text-[12px] leading-[17px] text text-gray-service')}
              right={index === 0}
            >
              {item}
            </ColumnText>
          </div>
        ))}
    </div>
  )
}

const enum TableTitleType {
  ENDPOINT,
  SESSION,
}

const TableTitleRow = ({
  type,
  summaryViewMode,
}: {
  type: TableTitleType
  summaryViewMode: TableSummaryView
}) => {
  const countOrTotal = summaryViewMode === TableSummaryView.COST ? 'Total' : 'Count'
  const endpointTitles = ['API', 'CPU time', 'Storage', 'Traffic', countOrTotal]
  const sessionTitle = ['Session', 'API', 'CPU time', 'Storage', 'Traffic', countOrTotal]

  const titles = type === TableTitleType.ENDPOINT ? endpointTitles : sessionTitle

  return (
    <div
      className={twMerge(
        'px-[8px] mb-[2px] rounded-[4px]',
        'grid grid-cols-8',
        'bg-dark-dark2 h-[40px]',
      )}
    >
      {titles.length &&
        titles.map((item, index) => (
          <div
            key={index}
            className={twMerge(
              type === TableTitleType.ENDPOINT && index === 0 && 'col-span-4 *:truncate',
              type === TableTitleType.SESSION && index === 1 && 'col-span-3 *:truncate',
              ((type === TableTitleType.SESSION && index !== 1) ||
                (type === TableTitleType.ENDPOINT && index !== 0)) &&
                'col-span-1',
              'flex items-center',
            )}
          >
            <ColumnText
              className={twMerge('px-[8px]', 'text-[12px] leading-[17px] text-gray-service')}
              right={index === 0}
            >
              {item}
            </ColumnText>
          </div>
        ))}
    </div>
  )
}

const TableEndpointList = ({
  endpoints,
  summaryViewMode,
}: {
  endpoints: EndpointData[]
  summaryViewMode: TableSummaryView
}) => {
  return (
    <>
      {endpoints.map(({ title, directMethods, indirectMethods, sessions, ...summary }, index) => {
        const methods = [...directMethods, ...indirectMethods].sort(defaultSortUsingSummary)
        return (
          <TableEndpointElement
            key={index}
            data={[title, ...transformSummary({ ...summary }, summaryViewMode)]}
            methods={methods}
            sessions={sessions}
            summaryViewMode={summaryViewMode}
          />
        )
      })}
    </>
  )
}

const TableEndpointElement = ({
  data,
  methods,
  sessions,
  summaryViewMode,
}: {
  data: string[]
  methods: MethodData[]
  sessions: EndpointSessionData[]
  summaryViewMode: TableSummaryView
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [mouseDownTime, setMouseDownTime] = useState(0)

  const handleMouseDown = useCallback(() => {
    setMouseDownTime(Date.now())
  }, [])

  const handleClick = useCallback(() => {
    const duration = Date.now() - mouseDownTime

    // Threshold duration to differentiate between click and text selection
    const threshold = 200 // milliseconds

    if (duration < threshold) {
      setIsOpen((state) => !state)
    }
  }, [mouseDownTime, setIsOpen])

  return (
    <>
      {!isOpen ? (
        <TableRowContainer
          onMouseDown={handleMouseDown}
          onMouseUp={handleClick}
          data={data}
          containerClassName="bg-dark-dark1 hover:bg-dark-dark2 h-[56px]"
          firstTextClassName="text-[16px] leading-[22px] text-gray-service"
          otherTextClassName="text-[12px] leading-[17px] text-gray-service"
        />
      ) : (
        <TableEndpointCardContainer
          onMouseDown={handleMouseDown}
          onMouseUp={handleClick}
          data={data}
          methods={methods}
          sessions={sessions}
          summaryViewMode={summaryViewMode}
        />
      )}
    </>
  )
}

function UploadFileInput(props: {
  className: string
  onChange: (fileEvent: ChangeEvent<HTMLInputElement>) => void
}) {
  return (
    <div className={props.className}>
      <label
        htmlFor="upload"
        className="flex items-center justify-center px-2 bg-electro hover:cursor-pointer rounded"
      >
        Upload
      </label>
      <input
        onChange={props.onChange}
        id="upload"
        type="file"
        placeholder="upload trace"
        className="hidden"
      />
    </div>
  )
}
