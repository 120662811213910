export interface SliceArgument {
  key?: string
  value: string
}

export interface Slice {
  id: number
  title: string
  /**
   * Nanoseconds
   */
  start: number
  /**
   * Nanoseconds
   */
  end: number
  color: string
  /**
   * Number - starts from 0
   */
  level: number
  /**
   * Number - slice position index per level
   */
  levelPositionIndex?: number

  parent: Slice | null
  root: Slice | null

  rootPositionIndex: number
  children?: Slice[]

  threadId: number
  closureId: number | null
  objectId: number
  runnableId: number
  // arguments is a reserved word in JS
  args: SliceArgument[]
  /**
   * Status Flag - if slice is marked as too long, slow, concerned
   **/
  isConcerned?: boolean
  /**
   * Level of concerned slice
   **/
  concernedLevel?: number
  /**
   * Status Flag - if slice is a Cluster - a group of merged slices into one slice
   **/
  isCluster?: boolean
  /**
   * Status Flag - if slice is a Network Request.
   **/
  isNetworkRequest: boolean
  /**
   * Array of Slices - storage for a root slice - it's child slices with {@link isNetworkRequest} equals true.
   * Created for quick check for execution path if it's slices pass through stack with network request.
   **/
  stackNetworkRequests?: Slice[]
  /**
   * Status Flag - if slice is a logged event such as input event (tap/swipe)
   **/
  isLoggedEvent: boolean
  /** Added for {@link PsChartStore.isStaticPageMode} */
  extra?: Extra

  hashes?: { key: string; value: number }[]
}

export interface SliceStats {
  title: string
  total: number
  average: number
  occurrences: number
}

export interface SliceRange {
  /**
   * Nanoseconds
   */
  start: number
  /**
   * Nanoseconds
   */
  end: number
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSlice = (object: Record<string, any> | null): object is Slice => {
  return object !== null && 'threadId' in object
}

export const isRangeCrossingOrInside = (
  checkingRange: SliceRange,
  withinRange: SliceRange,
): boolean => {
  const isInside = checkingRange.start >= withinRange.start && checkingRange.end <= withinRange.end

  const isCrossing = checkingRange.start < withinRange.end && checkingRange.end > withinRange.start

  if (isCrossing) {
    // Calculate the overlap length
    const overlapStart = Math.max(checkingRange.start, withinRange.start)
    const overlapEnd = Math.min(checkingRange.end, withinRange.end)
    const overlapLength = overlapEnd - overlapStart

    // Calculate the length of the checkingRange
    const checkingRangeLength = checkingRange.end - checkingRange.start

    // Check if the overlap is greater than 30% of the checkingRange length
    if (overlapLength > 0.4 * checkingRangeLength) {
      return true
    }
  }

  return isInside
}

export const OBJECT_ID_EMPTY_VALUE = -1

/** Added for {@link PsChartStore.isStaticPageMode} */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Extra = { [key: string]: any }
